<template>
    <div class="compressor-place">
        <div class="first-line">
            <p class="middle-high" v-if="name">{{name}}</p>
            <p class="middle-high" v-if="!name">{{mac}}</p>
            <button @click="openWindowParam()">
                <img src="@/assets/imgs/pencil.svg" alt="">
            </button>
        </div>
        <div class="param-place">
            <div class="param-column">
                <div class="param-el" popup-text="Давление внутреннее, бар" v-if="type_controller=='Q1' || type_controller=='MAM-6090'">
                    <div class="icon-param">
                        <img src="@/assets/imgs/pressure-internal.svg" alt="">
                    </div>
                    <p class="the-biggest">{{pressureInShow}}</p>
                    <p class="small-text name-param">Давление внутреннее, бар</p>
                </div>
                <div class="param-el" popup-text="Давление на выходе, бар">
                    <div class="icon-param">
                        <img src="@/assets/imgs/pressure-outer.svg" alt="">
                    </div>
                    <p class="the-biggest">{{pressureOutShow}}</p>
                    <p class="small-text name-param">Давление на выходе, бар</p>
                </div>
                <div class="param-el" popup-text="Температура, °C">
                    <div class="icon-param">
                        <img src="@/assets/imgs/temperature.svg" alt="">
                    </div>
                    <p class="the-biggest">{{tempShow}}</p>
                    <p class="small-text name-param">Температура, °C</p>
                </div>
                <div class="param-el" popup-text="Вибрация, мм/с" v-if="true">
                    <div class="icon-param">
                        <img src="@/assets/imgs/vibration.svg" alt="">
                    </div>
                    <p class="the-biggest">{{vibrationShow}}</p>
                    <p class="small-text name-param">Вибрация, мм/с</p>
                </div>
                <div class="param-el" popup-text="Ток главного двигателя, A">
                    <div class="icon-param">
                        <img src="@/assets/imgs/bolt.svg" alt="">
                    </div>
                    <p class="the-biggest">{{engineCurrentShow}}</p>
                    <p class="small-text name-param">Ток главного двигателя, A</p>
                </div>
                <div class="param-el" popup-text="Время с момента включения, ч" v-if="type_controller=='Q1' || type_controller=='MAM-860'">
                    <div class="icon-param">
                        <img src="@/assets/imgs/time-on.svg" alt="">
                    </div>
                    <p class="the-biggest">{{totalTimeShow}}</p>
                    <p class="small-text name-param">Время с момента включения, ч</p>
                </div>
                <div class="param-el" popup-text="Напряжение, В" v-if="type_controller=='MAM-6090' || type_controller=='MAM-860'">
                    <div class="icon-param">
                        <img src="@/assets/imgs/vibration.svg" alt="">
                    </div>
                    <p class="the-biggest">{{voltageShow}}</p>
                    <p class="small-text name-param">Напряжение, В</p>
                </div>
            </div>
            <div class="param-column">
                <div class="param-el" popup-text="Часы с нагрузкой, ч">
                    <div class="icon-param">
                        <img src="@/assets/imgs/time-onload.svg" alt="">
                    </div>
                    <p class="the-biggest">{{onloadTimeShow}}</p>
                    <p class="small-text name-param">Часы с нагрузкой, ч</p>
                </div>
                <div class="param-el" popup-text="Часы без нагрузки, ч" v-if="type_controller=='Q1'">
                    <div class="icon-param">
                        <img src="@/assets/imgs/time-offload.svg" alt="">
                    </div>
                    <p class="the-biggest">{{offLoadTimeShow}}</p>
                    <p class="small-text name-param">Часы без нагрузки, ч</p>
                </div>
                <div class="param-el" popup-text="Часы с нагрузкой/без нагрузки, ч" v-if="type_controller=='Q1'">
                    <div class="icon-param">
                        <img src="@/assets/imgs/time-clock.svg" alt="">
                    </div>
                    <p class="the-biggest">{{workPercentShow}}</p>
                    <p class="small-text name-param">Часы с нагрузкой/без нагрузки, ч</p>
                </div>
                <div class="param-el color-param-to" popup-text="До замены воздушного фильтра, ч">
                    <div class="icon-param">
                        <img src="@/assets/imgs/time-air-filter.svg" alt="">
                    </div>
                    <p class="the-biggest">{{timeAirFilterShow}}</p>
                    <p class="small-text name-param">До замены воздушного фильтра, ч</p>
                </div>
                <div class="param-el color-param-to" popup-text="До замены фильтра сепаратора, ч">
                    <div class="icon-param">
                        <img src="@/assets/imgs/time-siparator.svg" alt="">
                    </div>
                    <p class="the-biggest">{{separatorFilterShow}}</p>
                    <p class="small-text name-param">До замены фильтра сепаратора, ч</p>
                </div>
                <div class="param-el color-param-to" popup-text="До замены масляный фильтр, ч">
                    <div class="icon-param">
                        <img src="@/assets/imgs/time-oil.svg" alt="">
                    </div>
                    <p class="the-biggest">{{timeOilShow}}</p>
                    <p class="small-text name-param">До замены масляный фильтр, ч</p>
                </div>
                <div class="param-el color-param-to" popup-text="До замены масла, ч" v-if="type_controller=='MAM-6090' || type_controller=='MAM-860'">
                    <div class="icon-param">
                        <img src="@/assets/imgs/time-oil.svg" alt="">
                    </div>
                    <p class="the-biggest">{{timeOilLiquidShow}}</p>
                    <p class="small-text name-param">До замены масла, ч</p>
                </div>
                <div class="param-el color-param-to" popup-text="До обслуживания эл.системы, ч" v-if="type_controller=='MAM-6090' || type_controller=='MAM-860'">
                    <div class="icon-param">
                        <img src="@/assets/imgs/time-bolt.svg" alt="">
                    </div>
                    <p class="the-biggest">{{timeElSystemShow}}</p>
                    <p class="small-text name-param">До обслуживания эл.системы, ч</p>
                </div>
            </div>
            <div class="param-column">
                <div class="param-el color-param-to" popup-text="До замены масла, ч" v-if="type_controller=='Q1'">
                    <div class="icon-param">
                        <img src="@/assets/imgs/time-oil.svg" alt="">
                    </div>
                    <p class="the-biggest">{{timeOilLiquidShow}}</p>
                    <p class="small-text name-param">До замены масла, ч</p>
                </div>
                <div class="param-el color-param-to" popup-text="До обслуживания эл.системы, ч" v-if="type_controller=='Q1'">
                    <div class="icon-param">
                        <img src="@/assets/imgs/time-bolt.svg" alt="">
                    </div>
                    <p class="the-biggest">{{timeElSystemShow}}</p>
                    <p class="small-text name-param">До обслуживания эл.системы, ч</p>
                </div>
                <div class="param-el" popup-text="Время с момента включения, ч" v-if="type_controller=='MAM-6090'">
                    <div class="icon-param">
                        <img src="@/assets/imgs/time-on.svg" alt="">
                    </div>
                    <p class="the-biggest">{{totalTimeShow}}</p>
                    <p class="small-text name-param">Время с момента включения, ч</p>
                </div>
                <div class="param-el green-status" popup-text="Статус установки">
                    <p class="plain-text">{{ statusDescriptionShwo }}</p>
                    <p class="small-text">Статус установки</p>
                </div>
                <div class="param-el yellow-status" popup-text="Сигнализация" v-if="type_controller=='MAM-6090' || type_controller=='MAM-860'">
                    <p class="plain-text">{{ alarmDescriptionShow }}</p>
                    <p class="small-text">Сигнализация</p>
                </div>
                <div class="param-el red-status" popup-text="Активная ошибка">
                    <p class="plain-text">{{ errorDescriptionShow }}</p>
                    <p class="small-text">Активная ошибка</p>
                </div>
                <button class="white-status" @click="openGraphWindow()" popup-text="Графики">
                    <img src="@/assets/imgs/icon-chart.svg" alt="">
                </button>
                <button class="white-status" @click="openWindowError()" popup-text="Журнал ошибок">
                    <img src="@/assets/imgs/error-archive.svg" alt="">
                </button>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'Compressor',
    props: ['type_controller', 'name', 'pressure_in', 'pressure_out', 'temperature', 'vibration', 'error', 'status', 'work_percent', 'total_time', 'onload_time', 'off_load_time',
            'time_air_filter', 'time_oil', 'id', 'mac', 'errorDescription', 'statusDescription', 'engine_current', 'separator_filter', 'time_oil_liquid', 'time_el_system',
            'voltage', 'alarm', 'alarmDescription', 'lastDate'],
    emits: ['open-graf', 'open-param', 'open-error'],
    methods: {
        openGraphWindow() {
            this.$emit('open-graf', this.id, this.name, this.mac, this.type_controller)
        },
        openWindowParam() {
            this.$emit('open-param', this.id, this.name)
        },
        openWindowError() {
            this.$emit('open-error', this.id, this.name, this.type_controller)
        }
    },
    computed: {
        flagActualData() {
            return 300 > (new Date().getTime() - new Date(this.lastDate).getTime())/1000
        },
        pressureInShow() {
            let answer = (this.pressure_in/1000).toFixed(1);
            return (answer != '0.0' && this.flagActualData)? answer : '-';
            // return this.flagActualData? 'good':'bad'
        },
        pressureOutShow() {
            const divider = this.type_controller=='Q1'?1000:10
            let answer = (this.pressure_out/divider).toFixed(1);
            return (answer != '0.0' && this.flagActualData)? answer : '-';
        },
        tempShow() {
            const divider = this.type_controller=='Q1'?1000:1
            let answer = (this.temperature/divider).toFixed(1);
            return (answer != '0.0' && this.flagActualData)? answer : '-';
        },
        vibrationShow() {
            if (this.vibration) {
                let answer = this.vibration.toFixed(2);
                return (answer != '0.00' && this.flagActualData)? answer : '-';
            }
            return '-';
        },
        workPercentShow() {
            return (this.work_percent && this.flagActualData)? this.work_percent : '-';
        },
        totalTimeShow() {
            return (this.total_time && this.flagActualData)? this.total_time : '-';
        },
        onloadTimeShow() {
            return (this.onload_time && this.flagActualData)? this.onload_time : '-';
        },
        offLoadTimeShow() {
            return (this.off_load_time && this.flagActualData)? this.off_load_time : '-';
        },
        timeAirFilterShow() {
            // return (this.time_air_filter && this.flagActualData)? this.time_air_filter : '-';
            return this.time_air_filter? this.time_air_filter : '-';
        },
        timeOilShow() {
            // return (this.time_oil && this.flagActualData)? this.time_oil : '-';
            return this.time_oil? this.time_oil : '-';
        },
        engineCurrentShow() {
            if (this.engine_current) {
                let answer = (this.engine_current/10).toFixed(1);
                return (answer != '0.0' && this.flagActualData)? answer : '-';
            }
            return '-';
        },
        separatorFilterShow() {
            // return (this.separator_filter && this.flagActualData)? this.separator_filter : '-';
            return this.separator_filter? this.separator_filter : '-';
        },
        timeOilLiquidShow() {
            // return (this.time_oil_liquid && this.flagActualData)? this.time_oil_liquid : '-';
            return this.time_oil_liquid? this.time_oil_liquid : '-';
        },
        timeElSystemShow() {
            // return (this.time_el_system && this.flagActualData)? this.time_el_system : '-';
            return this.time_el_system? this.time_el_system : '-';
        },
        voltageShow() {
            return (this.voltage && this.flagActualData)? this.voltage : '-';
        },
        statusDescriptionShwo() {
            return this.flagActualData?this.statusDescription +  '(' + this.status + ')':'Нет соединения'
        },
        alarmDescriptionShow() {
            return this.flagActualData?this.alarmDescription +  '(' + this.alarm + ')':'Нет соединения'
        },
        errorDescriptionShow() {
            return this.flagActualData?this.errorDescription +  '(' + this.error + ')':'Нет соединения'
        }
    },
}

</script>
<style noscoped lang="scss">
    .compressor-place {
        max-width: 573px;
        min-width: 358px;
        height: 43.6vh;
        min-height: 337px;
        border-radius: 4px;
        background: var(--gray-color);
    }
    .first-line {
        display: flex;
        justify-content: space-between;
        padding: 3px 5px;

        button {
            cursor: pointer;
            background: none;
            padding: 2px 3px;
            border-radius: 3px;
            transition: all .2s ease;
            
            &:hover {
                background: #f8f6f6;
            }
            &:active {
                background: var(--background-white, #FCFCFC);
            }
        }
    }
    .param-place {
        display: flex;
        justify-content: space-between;
        padding: 0 19px;
        .param-column {
            width: 33%;
        }

        .color-param-to {
            background: rgba(149, 149, 253, 0.39);
        }
        .param-el {
            // width: 9vw;
            width: 100%;
            min-width: 117px;
            height: 5.5vh;
            min-height: 49px;
            border-radius: 5px;
            border: 2px solid var(--background-white, #FCFCFC);
            margin-bottom: 10px;
            position: relative;
            padding: 1px 11px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .icon-param {
                position: absolute;
                top: 4px;
                right: 4px;
                img {
                    height: 4vh;
                }
            }
            &:after {
                position: absolute;
                z-index: 1;
                white-space: nowrap;
                // height: 100%;
                top: 102%;
                background: var(--background-white);
                font-size: 14px;
                padding: 2px 5px;
                box-shadow: 0px 20px 40px 9px rgba(0, 0, 0, 0.26);
                display: flex;
                border-radius: 3px;
                flex-direction: column;
                justify-items: center;
                align-self: center;
                content: attr(popup-text);
                color: black;
                opacity: 0;
                transition: opacity .2s ease;
            }

            &:hover::after {
                // &:after{
                opacity: 1;
            }
        }
        button {
            width: 100%;
            min-width: 117px;
            height: 5.5vh;
            min-height: 49px;
            border-radius: 5px;
            border: 2px solid var(--background-white, #FCFCFC);
            margin-bottom: 10px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .2s ease;

            &:hover {
                background: #f8f6f6;
            }
            &:active {
                background: var(--gray-color, #EDEDED);
            }

            &:after {
                position: absolute;
                z-index: 1;
                white-space: nowrap;
                // height: 100%;
                top: 102%;
                background: var(--background-white);
                font-size: 14px;
                padding: 2px 5px;
                box-shadow: 0px 20px 40px 9px rgba(0, 0, 0, 0.26);
                display: flex;
                border-radius: 3px;
                flex-direction: column;
                justify-items: center;
                align-self: center;
                content: attr(popup-text);
                color: black;
                opacity: 0;
                transition: opacity .2s ease;
            }
            &:hover::after {
                // &:after{
                opacity: 1;
            }
        }
        .white-status {
            background: var(--background-white, #FCFCFCFF);
        }
        .green-status {
            background: var(--green-color, #B3DDBE);
        }
        .red-status {
            background: var(--red-color, #F97676);
        }
        .yellow-status {
            background: #dff36e;
        }
    }
    @media screen and (max-width: 1700px) {
        .name-param {
            display: none;
        }
        .green-status {
            .middle-high {
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .red-status {
            .plain-text {
                font-family: Lato;
                font-size: 9px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .yellow-status {
            .plain-text {
                font-family: Lato;
                font-size: 9px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .param-column {
            width: 33.3%;
        }
        
    }
    @media screen and (max-width: 1210px) {
        .name-param {
            display: contents;
        }
        .green-status {
            .middle-high {
                font-family: Lato;
                font-size: 22px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0%;
            }
        }
        .red-status {
            .plain-text {
                font-family: Lato;
                font-size: 14px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0%;
            }
        }
        .yellow-status {
            .plain-text {
                font-family: Lato;
                font-size: 14px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0%;
            }
        }
    }
    @media screen and (max-width: 1150px) {
        .name-param {
            display: none;
        }
        .green-status {
            .middle-high {
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .red-status {
            .plain-text {
                font-family: Lato;
                font-size: 9px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .yellow-status {
            .plain-text {
                font-family: Lato;
                font-size: 9px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .param-column {
            width: 33.3%;
        }
    }
    @media screen and (max-width: 810px) {
        .name-param {
            display: contents;
        }
        .green-status {
            .middle-high {
                font-family: Lato;
                font-size: 22px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0%;
            }
        }
        .red-status {
            .plain-text {
                font-family: Lato;
                font-size: 14px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0%;
            }
        }
        .yellow-status {
            .plain-text {
                font-family: Lato;
                font-size: 14px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0%;
            }
        }
    }
    @media screen and (max-width: 610px) {
        .name-param {
            display: none;
        }
        .green-status {
            .middle-high {
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .red-status {
            .plain-text {
                font-family: Lato;
                font-size: 9px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .yellow-status {
            .plain-text {
                font-family: Lato;
                font-size: 9px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .param-place {
            padding: 0 1px;
        }
        .param-column {
            width: 33.3%;
        }
    }
    @media screen and (max-height: 887px) {
        .param-place {
            .param-el {
                margin-bottom: 2px;
            }
            button {
                margin-bottom: 2px;

                &:after {
                    display: none;
                }
            }
        }
        .first-line {
            p {
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            img {
                height: 13px;
            }
        }
        .red-status {
            .plain-text {
                font-family: Lato;
                font-size: 9px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .yellow-status {
            .plain-text {
                font-family: Lato;
                font-size: 9px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
</style>
