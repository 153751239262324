<template>
    <div class="background-window" @click.self="closeWindow">
        <div class="window-param">
            <div class="head-window">
                <h2 class="name-window-param middle-high">{{nameWinow}}</h2>
                <button @click="closeWindow">
                    <img src="@/assets/imgs/crosser-black.svg" alt="">
                </button>
            </div>
            <div class="body-window">
                <div class="input-form-compressor" name-input="">
                    <input class="name-compressor-input"  type="text" v-model="name">
                    <label class="plain-text" :class="{ active: name }">Наименование компрессора</label>
                </div>
                <div class="input-form-compressor" name-input="">
                    <p class="plain-text">Тип: <strong>{{type_controller}}</strong></p>
                </div>
                <div class="input-form-compressor" name-input="">
                    <p class="plain-text">MAC адрес: <strong>{{mac}}</strong></p>
                </div>
                <div class="input-form-compressor" name-input="">
                    <p class="plain-text">Дата регистрации: <strong>{{date.slice(0, 19)}}</strong></p>
                </div>
                <div class="input-form-compressor" name-input="" v-if="userOptions.role == 'user'">
                    <!-- <input  type="text"  :value="companyName"> -->
                    <el-select
                        v-model="companyId"
                        placeholder="Select"
                        size="small"
                    >
                        <el-option
                            v-for="item in companyList"
                            :key="item.label"
                            :label="item.label"
                            :value="item.id"
                        />
                    </el-select>
                    <label class="plain-text" :class="{ active: companyId }">Название компании</label>
                </div>
                <div class="button-place">
                    <button class="button-form-compressor plain-text red-color" @click="closeWindow">Отменить</button>
                    <button class="button-form-compressor plain-text blue-color" @click="postData">Применить</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: "WindowParam",
    emits: ['closeWindow'],
    props: ['id', 'mac', 'nameCompressor', 'date', 'company_id', 'type_controller'],
    data() {
        return {
            name: '',
            nameWinow: '',
            companyName: '',
            companyId: 0
        }
    },
    computed: {
        nameWindow() {
            if (this.nameCompressor) {
                return this.nameCompressor
            } else {
                return this.mac
            }
        },
        ...mapGetters({
            userOptions: 'getUserOptions',
            companyList: 'getCompanyList'
        })
    },
    methods: {
        ...mapActions({
            setCompressorSettings: 'putCompressorSettings',
            getCompanyList: 'getApiCompanyList'
        }),
        closeWindow() {
            this.$emit('closeWindow')
        },
        postData() {
            let options = {
                name: this.name,
                id: this.id,
                company_id: this.companyId
            }
            console.log(options)
            this.setCompressorSettings(options)
            this.closeWindow()
        }
    },
    created() {
        this.name = this.nameCompressor
        this.companyId = this.company_id
        if (this.nameCompressor) {
            this.nameWinow = this.nameCompressor
        } else {
            this.nameWinow = this.mac
        }
        this.getCompanyList();
    }
}
</script>
<style noscoped lang="scss">
.background-window{
    position: absolute;
    z-index: 2;
    top:0;
    left:0;
    background: rgba(0, 0, 0, 0.295);
    width: 100vw;
    height: 100vh;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.window-param{
    background: var(--background-white, #FCFCFC);
    cursor: auto;
    width: 398px;
    border-radius: 4px;
    padding: 12px 9px;
    display: flex;
    flex-direction: column;

    .head-window {
        display: flex;
        justify-content: space-between;

        button {
            padding: 1px 2px;
            background: none;
            cursor: pointer;
        }
    }
    .input-form-compressor {
        position: relative;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        
        
        // input {
        .name-compressor-input{
            padding: 3px 6px;
            width: 343px;
            min-width: 285px;
            padding: 3px 6px;
            border-radius: 4px;
            border: 1px solid var(--gray-dark-color, #949494);
        }

        label {
            order: -1;
            pointer-events: none;
            margin: 1px 1px;
            padding: 0 6px;
            // transform: translateY(20px);
            background: var(--background-white);
            transition: all .2s ease;
            left: 3px;
            border-radius: 4px;
            color: var(--gray-dark-color, #949494);
            position: absolute;
            // left: 6px;
            white-space: nowrap;
        }
        input:focus ~ label {
            // bottom: 100%;
            transform: translateY(-14px);
        }
        .active {
            transform: translateY(-14px);
        }
        
    }
    .body-window {
        align-self: center;
    }
    .button-place {
        margin-top: 26px;
        display: flex;
        flex-direction: column;

        button {
            transition: all .2s ease;
            box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        }
        button:hover {
            box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
        }
        button:active {
            box-shadow: none;
        }
    }
    .button-form-compressor:first-child {
        margin-bottom: 11px;
    }
    .button-form-compressor {
        // padding: 5px 0px;
        width: 343px;
        color: #FFF;
        cursor: pointer;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 3px 0px;
        transition: all ease .3s;
    }
    .red-color {
            background: var(--red-color, #F97676); 
        }
    .blue-color {
        background: var(--background-blue, #3F51B5);
    }
}
@media screen and (max-width: 420px) {
    .window-param{
        width: 361px;
        .input-form-compressor {
            input {
                width: 297px;
            }
        }
        .button-form-compressor {
            width: 297px;
        }
        .middle-high {
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}

</style>