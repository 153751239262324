import { createStore } from 'vuex'
import { ElMessage } from 'element-plus'
import axios from '../../config-axios'

export default createStore({
  state: {
    userOptions: {
      loginStatus: false,
      username: null,
      role: 'guest'
    },
    windowLogin: {
      loaderButton: false
    },
    compressorsList: {},
    statusDescriptions: {},
    companyList: [],
  },
  getters: {
    getUserOptions(state) {
      return state.userOptions
    },
    getLoaderLoginButton(state) {
      return state.windowLogin.loaderButton
    },
    getCompressorsList(state) {
      return state.compressorsList
    },
    getStatusDescriptions(state) {
      return state.statusDescriptions
    },
    getCompanyList(state) {
      return state.companyList
    },
  },
  mutations: {
    setLogin(state, options) {
      state.userOptions.loginStatus = options
    },
    setLoaderButtonLogin(state, options) {
      state.windowLogin.loaderButton = options
    },
    setUserParam(state, options) {
      state.userOptions.loginStatus = true
      state.userOptions.username = options.username
      state.userOptions.role = options.role
    },
    setCompressorsList(state, options) {
      state.compressorsList = options
    },
    setCompressorParam(state, options) {

      let indexCom = null
      for (const property in state.compressorsList) {
        state.compressorsList[property].compressors.find((el, index)=>{
          if (options.id == el.id) {
            indexCom = index
            return true
          }
        })
        if (indexCom != null) {
          state.compressorsList[property].compressors[indexCom] = options;
          indexCom = null;
        }
      }
      
    },
    setStatusDescriptions(state, options) {
      state.statusDescriptions = options
    },
    setCompanyList(state, options) {
      state.companyList = options
      state.companyList.unshift({ id: 0, label: 'Не зарегистрированные' })
    },
  },
  actions: {
    async postLogin(context, data){
      context.commit('setLoaderButtonLogin', true)
      axios.defaults.headers.common = {}
      await axios.post('user/login/', data).then( response => {
        context.commit('setLogin', true)
        localStorage.setItem('token', response.data.token)
        axios.defaults.headers.common = {'Authorization': `Token ${response.data.token}`}
        context.commit('setLoaderButtonLogin', false)
        context.commit('setUserParam', response.data)
      }).catch( error => {
        console.log(error)
        ElMessage.error('Неверные параметры для входа')
        context.commit('setLoaderButtonLogin', false)
      })
    },
    async postLogout(context) {
      axios.defaults.headers.common = {}
      localStorage.setItem('token', '')
      context.commit('setLogin', false)
    },
    async checkUserInfo(context) {
      await axios.get('user/info/').then( (response) => {
        context.commit('setUserParam', response.data)
      }).catch( error => {
        console.log(error)
      })
    },
    async getCompressors(context) {
      await axios.get('compressor_point/').then( response => {
          context.commit('setCompressorsList', response.data)
      }).catch(error=>{
        console.log(error)
        // alert('Error request')
        ElMessage.error('Ошибка, не получилось получить данные о оборудовании')
      })
    },
    async getApiCompanyList(context) {
      await axios.get('company/').then(response => {
        context.commit('setCompanyList', response.data)
      }).catch(error=>{
        console.log(error)
        ElMessage.error('Ошибка, не удалось получить список компаний')
      })
    },
    async putCompressorSettings(context, options) {
      await axios.put('compressor/', options).then(response=>{
        context.commit('setCompressorParam', response.data)
        ElMessage.success('Параметры изменены')
      }).catch(error=>{
        console.log(error)
        // alert('Error request')
        ElMessage.error('Ошибка, не удалось изменить параметры')
      })
    },
    async getStatusDescriptions(context) {
      await axios.get('get_status_descriptions/').then(response => {
        context.commit('setStatusDescriptions', response.data)
      }).catch(error=>{
        console.log(error)
        ElMessage.error('Ошибка, определения статусов')
      })
    }
  },
  modules: {
  }
})
