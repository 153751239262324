<template>
    <div class="window-about">
        <button @click="$emit('closeWindow')">×</button>
        <div class="content-about-window">
            <img src="@/assets/imgs/slide_.png" alt="">
            <p>Датчик вибрации — это инновационное устройство, спроектированное с учетом современных требований промышленности и науки. Его высокочувствительные сенсоры реагируют на даже самые слабые вибрации и переводят их в электрические сигналы, которые могут быть легко интерпретированы и анализированы. Благодаря этой способности, датчики вибрации нашли применение в широком спектре областей.</p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'WindowAbout',
    data() {
        return {
            
        }
    },
    props: {
    }
}
</script>
<style scoped lang="scss">
.window-about {
    position: absolute;
    top: 2.5vh;
    left: 2.5vw;
    height: 95vh;
    width: 95vw;
    background-color: #3F51B5;
    border-radius: 0.5625rem;
    display: flex;
    flex-direction: column;
}
.content-about-window {
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
button{
    background: rgba(0, 0, 0, 0);
    color: #FCFCFC;;
    font-size: 2.3rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
}
img {
    margin-top: 5rem;
    height: 400px;
}
p {
    margin-top: 1.5rem;
    padding: 2rem;
    color: var(--white, #FCFCFC);
    font-size: 1.3rem;
    line-height: 170%; /* 1.7rem */
    text-align: justify;
    word-wrap: break-word;
    word-break: break-all; /* более приоритетно */
}
@media screen and (max-width: 540px) {
    img {
        height: 300px;
    }
    p {
    font-size: 1.2rem;
    line-height: 130%; /* 1.7rem */
}
}
</style>