<template>
    <div>
        <HighBoard
            @logOut="logOutClick"
        />
        <!-- <div class="high-board">
            <button class="logout" @click="logOutClick">
                <i class="fa-solid fa-arrow-right-from-bracket"></i>
                <span>logout</span>
            </button>
        </div> -->
        <div class="main-dask">
            <!-- <MyCollapse>
                <CompressorWidget
                    v-for="item, index in compressorsList.compressor" :key="item.id"
                    :name="item.name"
                    :mac="item.mac"
                    :pressure_in="compressorsList.param[index].pressure_in"
                    :pressure_out="compressorsList.param[index].pressure_out"
                    :temperature="compressorsList.param[index].temperature"
                    :vibration="compressorsList.param[index].vibration"
                    :error="compressorsList.param[index].error"
                    :errorDescription="getDiscriptionsOfStatus(StatusDescriptions.error, compressorsList.param[index].error)"
                    :status="compressorsList.param[index].status"
                    :statusDescription="getDiscriptionsOfStatus(StatusDescriptions.status, compressorsList.param[index].status)"
                    :work_percent="compressorsList.param[index].work_percent"
                    :total_time="compressorsList.param[index].total_time"
                    :off_load_time="compressorsList.param[index].off_load_time"
                    :onload_time="compressorsList.param[index].onload_time"
                    :time_air_filter="compressorsList.param[index].time_air_filter"
                    :time_oil="compressorsList.param[index].time_oil"
                    :engine_current="compressorsList.param[index].current_engine"
                    :separator_filter="compressorsList.param[index].time_separator_filter"
                    :time_oil_liquid="compressorsList.param[index].time_change_oil_liquid"
                    :time_el_system="compressorsList.param[index].time_el_system"
                    :id="item.id"
                    @open-graf="openGraphWindow"
                    @open-param="openParamWindow"
                    @open-error="openErrorWindow"
                />
            </MyCollapse> -->
            <div v-if="Object.keys(compressorsList).length != 1">
                <MyCollapse
                    v-for="keyCompany in Object.keys(compressorsList).sort((a, b) => {
                        if (a === 'unreg' && b !== 'unreg') {
                            return 1;
                        } else if (a !== 'unreg' && b === 'unreg') {
                            return -1;
                        } else {
                            return 0;
                        }
                    })"
                    :nameCollapse="compressorsList[keyCompany].showing_name_of_company"
                    :key="keyCompany"
                >   
                    <CompressorWidget
                        v-for="item in compressorsList[keyCompany].compressors" :key="item.id"
                        :name="item.name"
                        :mac="item.mac"
                        :type_controller="item.type_controller"
                        :pressure_in="item.pressure_in"
                        :pressure_out="item.pressure_out"
                        :temperature="item.temperature"
                        :vibration="item.vibration"
                        :error="item.error"
                        :errorDescription="getDiscriptionsOfStatus(StatusDescriptions[item.type_controller]?.errors, item.error)"
                        :status="item.status"
                        :statusDescription="getDiscriptionsOfStatus(StatusDescriptions[item.type_controller]?.status, item.status)"
                        :work_percent="item.work_percent"
                        :total_time="item.total_time"
                        :off_load_time="item.off_load_time"
                        :onload_time="item.onload_time"
                        :time_air_filter="item.time_air_filter"
                        :time_oil="item.time_oil"
                        :engine_current="item.current_engine"
                        :separator_filter="item.time_separator_filter"
                        :time_oil_liquid="item.time_change_oil_liquid"
                        :time_el_system="item.time_el_system"
                        :voltage="item.voltage"
                        :alarm="item.alarm"
                        :alarmDescription="getDiscriptionsOfStatus(StatusDescriptions[item.type_controller]?.alarm, item.alarm)"
                        :lastDate="item.last_time_writen"
                        :id="item.id"
                        @open-graf="openGraphWindow"
                        @open-param="openParamWindow"
                        @open-error="openErrorWindow"
                    />
                </MyCollapse>
            </div>
            <div class="solo-company" v-else v-for="company, companyName in compressorsList" :key="companyName">
                <CompressorWidget
                    v-for="item in company.compressors" :key="item.id"
                    :name="item.name"
                    :mac="item.mac"
                    :type_controller="item.type_controller"
                    :pressure_in="item.pressure_in"
                    :pressure_out="item.pressure_out"
                    :temperature="item.temperature"
                    :vibration="item.vibration"
                    :error="item.error"
                    :errorDescription="getDiscriptionsOfStatus(StatusDescriptions[item.type_controller]?.errors, item.error)"
                    :status="item.status"
                    :statusDescription="getDiscriptionsOfStatus(StatusDescriptions[item.type_controller]?.status, item.status)"
                    :work_percent="item.work_percent"
                    :total_time="item.total_time"
                    :off_load_time="item.off_load_time"
                    :onload_time="item.onload_time"
                    :time_air_filter="item.time_air_filter"
                    :time_oil="item.time_oil"
                    :engine_current="item.current_engine"
                    :separator_filter="item.time_separator_filter"
                    :time_oil_liquid="item.time_change_oil_liquid"
                    :time_el_system="item.time_el_system"
                    :voltage="item.voltage"
                    :alarm="item.alarm"
                    :alarmDescription="getDiscriptionsOfStatus(StatusDescriptions[item.type_controller]?.alarm, item.alarm)"
                    :lastDate="item.last_time_writen"
                    :id="item.id"
                    @open-graf="openGraphWindow"
                    @open-param="openParamWindow"
                    @open-error="openErrorWindow"
                />
            </div>
            <!-- <CompressorWidget
                v-for="item, index in compressorsList.compressor" :key="item.id"
                :name="item.name"
                :mac="item.mac"
                :pressure_in="compressorsList.param[index].pressure_in"
                :pressure_out="compressorsList.param[index].pressure_out"
                :temperature="compressorsList.param[index].temperature"
                :vibration="compressorsList.param[index].vibration"
                :error="compressorsList.param[index].error"
                :errorDescription="getDiscriptionsOfStatus(StatusDescriptions.error, compressorsList.param[index].error)"
                :status="compressorsList.param[index].status"
                :statusDescription="getDiscriptionsOfStatus(StatusDescriptions.status, compressorsList.param[index].status)"
                :work_percent="compressorsList.param[index].work_percent"
                :total_time="compressorsList.param[index].total_time"
                :off_load_time="compressorsList.param[index].off_load_time"
                :onload_time="compressorsList.param[index].onload_time"
                :time_air_filter="compressorsList.param[index].time_air_filter"
                :time_oil="compressorsList.param[index].time_oil"
                :id="item.id"
                @open-graf="openGraphWindow"
                @open-param="openParamWindow"
            /> -->
            <Transition name="fade" mode="out-in">
                <WindowGraph
                    v-if="switcherGraph"
                    @closeWindow="closeGraphWindow"
                    :name="graphWindow.name"
                    :id="graphWindow.id"
                    :mac="graphWindow.mac"
                    :type_controller="graphWindow.type_controller"
                />
            </Transition>
            <Transition name="fade" mode="out-in">
                <WindowParam
                    v-if="switcherParam"
                    @closeWindow="closeParamWindow"
                    :id="paramWindow.id"
                    :mac="paramWindow.mac"
                    :nameCompressor="paramWindow.name"
                    :date="paramWindow.date"
                    :type_controller="paramWindow.type_controller"
                    :company_id="paramWindow.company"
                />
            </Transition>
            <Transition name="fade" mode="out-in">
                <WindowError
                    v-if="switcherError"
                    @closeWindow="closeErrorWindow"
                    :id="errorWindow.id"
                    :name="errorWindow.name"
                    :errorBase="errorWindow.baseError"
                />
            </Transition>
        </div>
    </div>
</template>
<script>

import { mapMutations, mapGetters, mapActions } from 'vuex'
import CompressorWidget from '@/components/Simple_elements/CompressorWidget.vue'
import WindowGraph from '@/components/Simple_elements/WindowGraph.vue'
import WindowParam from '@/components/Simple_elements/WindowParam.vue'
import WindowError from '@/components/Simple_elements/WindowError.vue'
import HighBoard from '@/components/Simple_elements/HighBoard.vue'
import MyCollapse from '@/components/Simple_elements/MyCollapse.vue'

export default {
    name: 'Dashboard',
    computed: {
        ...mapGetters({
            userOptions: 'getUserOptions',
            compressorsList: 'getCompressorsList',
            StatusDescriptions: 'getStatusDescriptions'
        })
    },
    data() {
        return {
            switcherGraph: false,
            switcherParam: false,
            switcherError: false,
            paramWindow: {
            },
            graphWindow: {
            },
            errorWindow: {
            },
            flagStopTimeout: false
        }
    },
    components: {
        CompressorWidget,
        WindowGraph,
        WindowParam,
        WindowError,
        HighBoard,
        MyCollapse
    },
    methods: {
        ...mapMutations({
            setLogin: 'setLogin'
        }),
        ...mapActions({
            postLogout: 'postLogout',
            getCompressors: 'getCompressors',
            getStatusDescriptions: 'getStatusDescriptions',
        }),
        logOutClick() {
            // this.setLogin(!this.userOptions.loginStatus)
            this.postLogout()
        },
        openGraphWindow(id, name, mac, type_controller) {
            this.switcherGraph = true
            this.graphWindow = {
                id: id,
                name: name,
                mac: mac,
                type_controller: type_controller
            }
        },
        closeGraphWindow() {
            this.switcherGraph = false
            this.graphWindow = {}
        },
        openParamWindow(id, name) {
            this.switcherParam = true
            for (const property in this.compressorsList) {
                this.compressorsList[property].compressors.find(el=>{
                    if (el.id==id && el.name == name) {
                        this.paramWindow.id = el.id
                        this.paramWindow.mac = el.mac
                        this.paramWindow.name = el.name
                        this.paramWindow.date = el.created_date
                        this.paramWindow.company = el.company_id
                        this.paramWindow.type_controller = el.type_controller
                    }
                })
            }
        },
        closeParamWindow() {
            this.switcherParam = false
            this.paramWindow = {}
        },
        openErrorWindow(id, name, type_controller) {
            this.switcherError = true
            this.errorWindow = {
                id: id,
                name: name,
                baseError: this.StatusDescriptions[type_controller].errors

            }
        },
        closeErrorWindow() {
            this.switcherError = false
            this.errorWindow = {}
        },
        getDiscriptionsOfStatus(findArray, code) {
            // console.log(findArray, code)
            if (findArray) {
                let description = 'Неопределенный статус'; 
                findArray.find(el => {
                    if (el.code == code) {
                        description = el.description
                    }
                })
                return description
            } else {
                return ''
            }
        },
        updateData() {
            this.getCompressors();
            if (!this.flagStopTimeout) {
                setTimeout(this.updateData, 10000)
            }
        }
    },
    created() {
        this.flagStopTimeout = false
        this.getCompressors();
        setTimeout(this.updateData, 10000)
        this.getStatusDescriptions()
    },
    beforeUnmount() {
        this.flagStopTimeout = true
    }
}

</script>
<style noscoped lang="scss">
// .high-board{
//     width: 100vw;
//     background: #3F51B5;
//     height: 7vh;
//     box-shadow: 3px 13px 8px 0px rgba(34, 60, 80, 0.2);
//     display: flex;
//     align-items: center;
    
//     .logout{
//         cursor: pointer;
//         margin: 0px 1vw;
//         height: 50%;
//         background: #fff;
//         padding: 4px 10px;
//         border-radius: 4px;
//         i{
//             margin: 0px 6px;
//             color:#3f51b5;
//         }
//     }
// }
.solo-company {
    overflow: hidden;
    transition: all .2s ease;
    padding: 24px 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}
.main-dask{
    height: 93vh;//issue: should be 93vh
    // display: flex;
    // align-items: flex-start;
    // justify-content: flex-start;
    // flex-direction: row;
    // flex-wrap: wrap;
    // align-content: flex-start;
    padding: 10px 1vw;
    overflow-y: auto;
}

</style>



